<template>
  <div>
    <form ref="myForm" class="text-left">
      <v-skeleton-loader
        v-if="showLoader"
        class="mx-auto rounded-circle"
        height="250px"
        max-height="250px"
        max-width="300"
        type="image"
      ></v-skeleton-loader>
      <v-avatar v-if="showLoader === false && showUserImage" size="250">
        <img
          :src="user.image"
          @click="select_image"
          class="pointer"
          alt="user-image"
        />
      </v-avatar>
      <div v-if="imageSrc">
        <vue-cropper
          :aspectRatio="ratio"
          class="mr-2 w-50"
          ref="cropper"
          :guides="true"
          :src="imageSrc"
        ></vue-cropper>
      </div>
      <v-card-actions class="mt-6">
        <v-row>
          <v-col>
            <input
              ref="file"
              type="file"
              name="image"
              accept="image/*"
              @change="setImage"
              class="d-none"
            />
            <Button
              :click_action="select_image"
              action_name="Modifier"
              style="width: 100%"
              @clicked="select_image"
              color="#11887E"
              class="mb-2"
            />
            <Button
              :click_action="cropImage"
              :loading="loading"
              action_name="Enregistrer"
              style="width: 100%"
              @clicked="cropImage"
            />
          </v-col>
        </v-row>
      </v-card-actions>
      <v-snackbar v-model="snackbar" :timeout="timeout">
        {{ text }}
        <v-btn color="blue" text @click="snackbar = false">
          Close
        </v-btn>
      </v-snackbar>
    </form>
  </div>
</template>
<script>
import { Button } from "@/components/base";
import { getUser, updatePhoto } from "@/services/users.api";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  name: "photo",
  components: {
    Button,
    VueCropper
  },
  data() {
    return {
      ratio: 1,
      imageName: "",
      showUserImage: true,
      imageSrc: "",
      croppedImageSrc: "",
      user: {
        image: null
      },
      user_image: "",
      text: "",
      snackbar: false,
      timeout: 2000,
      loading: false,
      isValid: true,
      showLoader: true
    };
  },
  mounted() {
    getUser().then(response => {
      this.user.image = process.env.VUE_APP_PHOTOS_PATH + response.data.image;
      this.showLoader = false;
    });
  },
  methods: {
    setImage: function(e) {
      const file = e.target.files[0];
      if (!file.type.includes("image/")) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = event => {
          this.imageSrc = event.target.result;

          // Rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
        this.imageName = file.name;
        this.showUserImage = false;
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    cropImage() {
      this.loading = true;
      // Get image data for post processing, e.g. upload or setting image src
      this.croppedImageSrc = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.showUserImage = false;
      //this.user.image = this.croppedImageSrc;
      this.uploadImage();
    },
    uploadImage() {
      const _self = this;
      this.$refs.cropper.getCroppedCanvas().toBlob(function(blob) {
        let formData = new FormData();
        formData.append("image_name", _self.imageName);
        formData.append("file", blob);
        updatePhoto(formData).then(response=>{
          _self.$store.commit('updateUserImage', response.data);
          let user = JSON.parse(localStorage.getItem('user'));
          user.image = response.data;
          localStorage.setItem('user', JSON.stringify(user));
          _self.showUserImage = true;
          _self.loading = false;
          _self.user.image = process.env.VUE_APP_PHOTOS_PATH + response.data;
          _self.imageSrc = false;
          _self.$swal.fire({ title: "Image modifiée avec succès", });
        })
      });
    },
    select_image() {
      this.$refs.file.click();
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
  .v-skeleton-loader__image {
    height: 100%;
  }
}
</style>
